.post{
    display:grid;
    grid-template-columns: minmax(0px, 1.2fr) minmax(300px, 1fr) minmax(0px, 1fr);
    transition: opacity 0.5s linear;
}

.post a{
  white-space: pre-wrap;
}

table td,th{
    border: solid 1px var(--secondary-color);
  padding:5px 20px;
}

table{
  border: solid 1px var(--secondary-color);
  border-collapse:collapse;
  margin:10px auto;
}

#postheader{

    min-height:40vh;
    position:sticky;
    grid-column: 1/4;

    background-size:cover;
    background-position:center;
}
.pcontainer{
    grid-column: 1/4;
    margin-top:5vh;
    max-width:80ch;
    margin:auto;
}

.pcontent{
    margin:auto;
    padding:0 20px 0 20px;
    max-width:98vw;
}

.pcontent h1{
    font-size:3em;
}

.pcontent a{
    text-decoration: underline;
}
.pcontent p{
margin:0 0 15px 0;
line-height:1.5;
}

.pcontent>div{

font-size:20px;
}

.pcontent>div>h1{
    margin-bottom:10px;
    margin-top:30px;
    letter-spacing:-0.03em;
    font-size:2.5em;
}
.pcontent h2{
    font-size:1.8em;
    margin-bottom:10px;
    font-weight:normal;
    margin-top:30px;
    letter-spacing:-0.03em;
}

.pcontent h3{
    font-size:1.5em;
    font-weight:300; 
    margin-bottom:10px;
    margin-top:30px;
}
.pcontent h4{
    font-size:1.4em;
    margin-bottom:10px;
    margin-top:30px;
}
.pcontent h5{
    font-size:1.4em;
    font-weight:300; 
    margin-bottom:10px;
    margin-top:30px;
}
.pcontent h6{
    font-size:1.2em;
    margin-bottom:10px;
    margin-top:30px;
}

.pcontent>div.columns{
    width:100%;
    column-count:2;
    column-width:40ch;
    column-gap: 5ch;;
    text-align:justify;
}

.pcontent>div>*:first-child{
    font-weight:800;
}


.pcontent>div>figure{
    max-width:85%;
    margin:20px 7.5% 20px 7.5%;
}

figure>video, figure>img{
width:100%;
height:auto;
}

figure>figcap{
    font-size:0.8em;
}
@media (min-width: 1000px) {

  #postheader{
      display:block;
      grid-column: 1/2;
      min-height:100vh!important;
      max-height:100vh;
      top:0;
  }
  .pcontainer{
      grid-column:2/4!important;
      margin-top:10vh;
  }

}

.columns img{
    max-width:100%;
}

.PageNavigation {
    font-size: 14px;
    display: block;
    width: auto;
    overflow: hidden;
  }
  
  .PageNavigation a {
    display: block;
    width: 50%;
    float: left;
    margin: 1em 0;
  }
  
  .PageNavigation .next {
    text-align: right;
  }
  

#back{
    margin: 20px;
    border-radius: 400px;
    width: 60px;
    height: 60px;
    background-color: var(--background-color);
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
    font-size: 30px;
    font-weight: 100;
    position: sticky;
    top:0;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-alt) !important;
}

.galleryThumb{
    width: 35%!important;
    float:right;
    min-width:300px;
    margin-top: 4px!important;
    margin-bottom: 4px!important;
    margin-left:4px!important;
  }

  .left{
      float:left!important;
  }
  
  .largeImg{
    width: 130%!important;
    margin-left: -15%!important;
    max-width: 100vw!important;
  }

.pcontent img{
    width:100%;
    margin-top:20px;
    margin-bottom:20px;
}

