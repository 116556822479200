:root {
  --background-color: #ffffff;
  --secondary-color: #efefef;
  --text: #4b4b4b;
  --text-alt: #BBB;
  --darken-rgba: rgba(0,0,0,0.05);
  box-sizing: content-box;
}

@media (prefers-color-scheme: dark) {
    :root {
      --background-color: #121212;
      --secondary-color: #232323;
      --text: #ffffff;
      --text-alt:#787878;
      --darken-rgba: rgba(255,255,255,.03);
    }
  }



  body{
    margin:0;
      background-color: var(--background-color);
      font-family: "Fira Sans", "Cantarell", sans-serif;
      color:var(--text);
  }

h1{
  font-size:3.5em;
  margin:0;
}
h2{
  margin:0;
}
p{
  line-height:1.5em;
}

a{
  text-decoration: none;
  font-weight:600;
  color:var(--text);
  white-space: nowrap;
}
#timeline a::before{
  content:" 🔗";
  white-space: nowrap;
}
