

  #timeline{
    max-width:1024px;
    position:relative;
    left:30px;
    margin:auto;
    border-left:solid;
    border-width:1px;
    border-color:var(--secondary-color);
}
.experience{
    position:relative;
    left:-23px;
    display:flex;
    margin-bottom:40px;
}
.icon>img{
  width:25px;
  height:25px;
  object-fit: contain;
}
.icon{
  background-color:#fff;
  min-width:45px;
  height:45px;
  display:flex;
  justify-content: center;align-items: center;
  border-radius:360px;
  border:solid 1px var(--secondary-color);
}
.date{
font-size:1.2em;
line-height:1;
font-weight:200;

}

.exp_info{
  margin-left:20px;
  display:flex;
  flex-direction: row;
}
.exp_info>.details{
    max-width:75ch;
    padding-right:20px;
}

.jef>.icon{
  background-color:rgb(159, 228, 159);
}

.eu>.icon{
  background-color:#000099;
}

.activity>.icon{
  background-color:#fec014;
}

.coe>.icon{
  background-color:rgb(179, 63, 63);
}

#timeline_controls{
  padding-bottom: 30px;
  max-width:1024px;
  margin:auto;
}
#timeline_controls>h2{
    font-weight:200;
    font-size: 2em;
    margin-bottom:10px;

}

#timeline_controls>div>label{
  display:inline-flex;
  padding:5px;
  align-items:center;
  justify-content: center;
  background-color:var(--secondary-color);
  border-radius:5px;
}

label>input{
  margin-right:5px;
}

.low{
  display:flex;
}