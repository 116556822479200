#skills {
    padding-bottom: 30px;
    max-width: 1024px;
    margin: auto;
}

#skills p{
max-width: 65ch;
line-height: 1.7em;
}

#skills>h2 {
    font-weight: 200;
    font-size: 2em;
    margin-bottom: 10px;

}

#skills>h3 {
    font-weight: 200;
    font-size: 1.5em;
}

#skills>#languages {
    max-width: 100%;
    display: flex;
    overflow-y: hidden;
    scrollbar-width: thin;
}

.languagetable {
    border-radius: 10px;
    background-color: var(--darken-rgba);
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    margin: 0 20px 20px 0px;
    background: repeating-radial-gradient( circle at 0 0, transparent 0, var(--background-color) 10px ), repeating-linear-gradient( var(--background-color), var(--secondary-color) );
    border: 1px var(--secondary-color) solid;
    background-attachment: fixed;
}

.langtitle {
    grid-column: 1/4;
    margin: 5px 0 0 12px;
    border-radius: 5px;
    font-weight: 600;

}

.langlevel>div {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 300;
}

.langlevel>div>span {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
}

.langlevel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--darken-rgba);
    margin: 10px;
    padding: 5px 7px 5px 7px;
    border-radius: 10px;
    backdrop-filter: blur(4px);
    border: solid 1px var(--secondary-color);
}

.languagetable:hover,.langlevel:hover{
border: solid 1px color-mix(in srgb, var(--secondary-color) 98%, white);
}

.langlevel>span {
    grid-column: 1/3;
    text-align: center;
    margin-bottom: 10px;
}



// Expanding card thing

* {
    box-sizing: border-box;
}

//Grid Container
.cards {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + 30px);
    position: relative;
    left: -15px;
}

//Cards
.card {
    margin: 15px;
    width: calc((100% / 3) - 30px);
    transition: all 0.2s ease-in-out;


    //media queries for stacking cards
    @media screen and (max-width: 991px) {
        width: calc((100% / 2) - 30px);
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    &:hover {
        .card__inner {
            filter: invert(0.1);
            transform: scale(1.05);

        }
    }

    &__inner {
        width: 100%;
        padding: 20px;
        position: relative;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        justify-content: left;
        align-items: center;

        background-color: var(--secondary-color);
        color: var(--text);
        font-size: 1.3em;
        text-transform: uppercase;
        font-weight:200;

        transition: all 0.2s ease-in-out;

        &:after {
            transition: all 0.3s ease-in-out;
        }

        .fa {
            width: 100%;
            margin-top: .25em;
        }
    }

    //Expander

    .closebutton {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    &__expander {
        transition: all 0.2s ease-in-out;
        background-color: var(--secondary-color);
        width: 100%;
        position: relative;

        display: flex;
        justify-content:space-evenly;
        align-items: center;
        color: var(--text);

        .fa {
            font-size: 0.75em;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;

            &:hover {
                opacity: 0.9;
            }
        }
    }

    &.is-collapsed {

        .card__inner {
            &:after {
                content: "";
                opacity: 0;
            }
        }

        .card__expander {
            max-height: 0;
            min-height: 0;
            overflow: hidden;
            margin-top: 0;
            opacity: 0;
        }
    }

    &.is-expanded {

        .card__inner {
            background-color: var(--secondary-color);

            &:after {
                content: "";
                opacity: 1;
                display: block;
                height: 0;
                width: 0;
                position: absolute;
                bottom: -30px;
                left: calc(50% - 15px);
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid var(--secondary-color);
            }

            //folder open icon
            .fa:before {
                content: "\f115";
            }
        }

        .card__expander {
            max-height: 1000px;
            min-height: 200px;
            overflow: visible;
            margin-top: 30px;
            opacity: 1;
            border-radius: 20px;
            text-transform: unset;
padding: 0 20px 0 20px;
font-size:1.2em;
line-height:1.8em;
        }

        &:hover {
            .card__inner {
                transform: scale(1);
            }
        }
    }

    &.is-inactive {
        .card__inner {
            pointer-events: none;
            opacity: 0.5;
        }

        &:hover {
            .card__inner {
                background-color: var(--secondary-color);
                transform: scale(1);
            }
        }
    }
}


//Expander Widths

//when 3 cards in a row
@media screen and (min-width: 992px) {

    .card:nth-of-type(3n+2) .card__expander {
        margin-left: calc(-100% - 30px);
    }

    .card:nth-of-type(3n+3) .card__expander {
        margin-left: calc(-200% - 60px);
    }

    .card:nth-of-type(3n+4) {
        clear: left;
    }

    .card__expander {
        width: calc(300% + 60px);
    }

}

//when 2 cards in a row
@media screen and (min-width: 768px) and (max-width: 991px) {

    .card:nth-of-type(2n+2) .card__expander {
        margin-left: calc(-100% - 30px);
    }

    .card:nth-of-type(2n+3) {
        clear: left;
    }

    .card__expander {
        width: calc(200% + 30px);
    }

}

.skillicon {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 15px;
}

.card__expander .skillicon{
	width: 80px;
  	height: unset;
}

@media (prefers-color-scheme: dark) {

    .skillicon,
    .closebutton {
        filter: invert(1);
    }
}
