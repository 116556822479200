  #name{
    font-weight:400;
  }
  #name>span{
    font-weight:100;
  }
  #front{
    display:grid;
    flex-wrap: wrap;
    min-height: calc( 100vh - 100px);
    align-content: center;
    max-width:1024px;
    margin:auto;
    grid-gap:20px;
  }
  #front>div{
    display:flex;
    flex-direction: column;
    justify-content:center;
    padding-left: 10px;
  }
#shortdesc{
color: var(--text-alt);
margin:0;
}
#longdesc{
  max-width:60ch;
  line-height:1.5;
  overflow:hidden;
}
#scrollmessage{
  grid-column-start: 1;
  grid-column-end: 3;
  text-align:center;
  color: var(--text-alt);
  height:100px;
}

#photocontainer{
  align-items:center;
}

@media(max-width:700px){
  #photocontainer{
    display:none!important;
  }
}
#photocontainer>div{
  width:300px;
  height:300px;
  border-radius:360px;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items:center;
  background-color: var(--secondary-color);
}

#photocontainer>div>img{
  width:280px;
  height:280px;
  border-radius:360px;
}
