#achievements-intro {
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    height: 100vh;
}

#mandate {
    background-color: black;
    .landing {
        scroll-snap-align: start;
        background-image: url('../assets/mandate.png');
        background-size: cover;
        background-repeat: no-repeat;
        article {
            width: 100%;
            display: flex;
            flex-direction: column;
            h1:nth-child(1) {
                margin-top: 55vh;
            }
            div {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, black 100%);
                height: 200px;
                width: 100%;
            }
            p {
                scroll-snap-align: center;
                font-size: calc(1em + 1.5vw);
                font-family: "Arial Black";
                background-color: rgba(0, 0, 0, 0.9);
                max-width: 30ch;
                color: white;
                padding: 20px;
                text-transform: uppercase;
                font-weight: 900;
                line-height: 1.5;
                margin: 8vh 15px;
            }
            p:nth-child(odd) {
                background-color: rgba(255, 255, 255, 0.9);
                color: black;
                max-width: 34ch;
                align-self: end;
            }
        }
    }
    h1 {
        font-weight: 900;
        font-size: 10vw;
        letter-spacing: 0.04em;
        position: relative;
        color: #FFCC00;
    }
    article {
        #finaltext {
            text-align: center;
            max-width: unset;
            font-size: 6vw;
        }
    }
}

.stats {
    text-align: center;
    text-transform: uppercase;
    scroll-snap-align: center;
    margin-top: 20vh;
    margin-bottom: 20vh;
}

.mandate-stats {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: space-evenly;
    scroll-snap-align: start;
    div {
        display: flex;
        min-width: 300px;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        position: relative;
        text-align: center;
        min-width: 33%;
        left: -2vw;
        color: white;
    }
    h3 {
        margin: 0;
    }
}

@media (max-width: 500px) {
    .mandate-stats {
        flex-direction: column;
    }
}

.num h1 {
    letter-spacing: unset!important;
}

.law-section {
    display: flex;
    flex-direction: column;
    width: 100vh;
    height: 100vw;
    transform: rotate(-90deg) translateY(-100vh);
    transform-origin: right top;
    overflow: scroll;
    scroll-snap-type: y mandatory;
    scroll-snap-align: start;
}

.law-section>* {
    position: relative;
    scroll-snap-align: center;
    transform: rotate(90deg) translateX(100vh);
    transform-origin: right top;
    width: 100vh;
    min-height: 100vw;
    scroll-snap-align: start;
}

#aiact-title>* {
    height: 100vh;
    width: 100vw;
}

#aiact-title {
    header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 3;
        position: relative;
        font-family: monospace;
        h1 {
            color: black;
            text-shadow: white 2px 2px 0, white -2px -2px 0, green 0 0 5px;
        }
        h2 {
            font-size: 5vw;
            color: white;
            font-family: monospace;
        }
    }
}

#aiact-code {
    position: absolute;
    top: 0;
    left: 0;
    font-family: monospace;
    font-size: 2em;
    word-wrap: break-word;
    z-index: 1;
    overflow-y: hidden;
    color: darkgreen;
    transform: perspective(95vw) rotateY(10deg) scale(1.2);
    overflow: hidden;
}

#aiact-mask {
    backdrop-filter: blur(8px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    mask: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, black 80%);
}

.scanlines {
    overflow: hidden;
    position: relative;
}

.scanlines:before,
.scanlines:after {
    display: block;
    pointer-events: none;
    content: "";
    position: absolute;
}

.scanlines:before {
    width: 100%;
    height: 2px;
    z-index: 2147483649;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0.75;
    animation: scanline 6s linear infinite;
}

.scanlines:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2147483648;
    background: linear-gradient( to bottom, transparent 50%, rgba(0, 0, 0, 0.3) 51%);
    background-size: 100% 4px;
    animation: scanlines 1s steps(60) infinite;
}

#chatgtp-container {
    width: 100vw;
    height: 100vh;
    background-color: white;
    font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    display: flex;
    .sidebar {
        margin-top: 30px;
        background-color: #F9F9F9;
        width: 260px;
        padding: 45px 20px 20px 20px;
        .newchat {
            .circle {
                background: white;
                border-radius: 300px;
                width: 1.4em;
                height: 1.4em;
                text-align: center;
                box-shadow: 0 0 3px 0px #ddd;
                font-size: 1.5em;
            }
            display:flex;
            gap:15px;
            align-items:center;
            font-weight:600;
            span:last-of-type {
                margin-left: auto;
            }
        }
    }
    .gtp-container {
        margin-top: 60px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .gtp-header {
            height: 50px;
            background-color: white;
            display: flex;
            font-size: 1.5em;
            b {
                margin-right: 5px;
            }
        }
        .gtp-chat {
            a:before {
               content:"🔗"; 
            }
            div {
                max-width: 80ch;
                display:flex;
                flex-direction: column;
                margin: auto;
                justify-content: flex-end;
                height:calc(90vh - 100px);
                p{
                    padding:7px;
                    max-width:62ch;
                    position:relative;
            
                }
                p:not(.gtp-you)::before{
                    content:'❅';
                    background: white;
                    border-radius: 300px;
                    width: 1.4em;
                    height: 1.4em;
                    text-align: center;
                    box-shadow: 0 0 3px 0px #ddd;
                    font-size: 1.5em;
                    position:absolute;
                    left:-2em;
                    line-height:1.4em;
                }
                p.gtp-you{
                    align-self: flex-end;
                    background-color: #efefef;
                    border-radius:10px;
                }
            }
        }
    }
}


/* ANIMATE UNIQUE SCANLINE */

@keyframes scanline {
    0% {
        transform: translate3d(0, 200000%, 0);
    }
}

@keyframes scanlines {
    0% {
        background-position: 0 50%;
    }
}

div {
    margin: 0;
    padding: 0;
}

div.scanlines {
    position: absolute;
}