.cv #front{
    min-height:100px;
    margin-bottom:15px;
}

.cv #shortdesc{
    display:none;
}

.cv #longdesc{
    max-width:1024px;
}

.cv #photocontainer > div{
    height:100px;
    width:100px;
}

.cv #photocontainer > div > img{
    height:120px;
    width:120px;
}

.cv{
    width:210mm;
    margin:auto;
    font-size:10px;
    padding:10mm;
    height:297mm;
}

.cv #scrollmessage{
    display:none;
    
}

.cv #descontainer{
    grid-column-start:2;
    grid-column-end:3;
}

.cv .skillicon{
    width: 25px;
height: 25px;
}

.cv .card__inner{
    font-weight:300;
    font-size:1.1em;
    padding:4px;
}

.cv .card {
    width:113px;
    margin:4px;
}

.cv .skillicon{
    margin-right:4px;
    margin-left:2px;
    width:15px;
    height:15px;
}

.cv .cards{
    font-size:8px;
    left:-4px;
}

.cv #skills>h2{
    margin: 10px 0 2px 0;
}
.cv #skills>h3{
    margin: 5px 0 2px 0;
}

// Languages

.cv #languages{
}

.cv #skills > #languages{
    display:block;
}

.cv .langlevel{
    margin: 5px 2px 5px 2px;
    padding: 3px 1px 3px 1px;
}

.cv .langlevel > div > span{
    padding-top:2px;
    font-size: 11px;
}
.cv .languagetable{
    font-size:8px;
    margin: 5px 0 5px 0;
    padding: 0px 2px 2px 2px;
}
.cv .langlevel>span{
    margin-bottom:2px;
}
.cv .langlevel > div{
    font-size:8px;
    padding:2px;
}
.cv .langtitle{
    padding-left:4px;
    font-size:10px;
}
.cv .langtitle>img{
    display:none;
}

.cv #skills>p{
    margin:1px 0 1px 0;
}

.cv #skills{
    width:33%;
    margin:unset;
    float:left;
}

.cv #timeline{
    float:right;
    width:66%;
    margin-top:20px;
}

.cv_title{
    font-weight: 200;
    font-size: 2em;
    position:relative;
    top:10px;
    left:18px;
}

.cv .details>h2{
    font-size:1.2em;
}

.cv .icon{
    min-width:30px;
    height:30px;
}
.cv .icon>img{
    min-width:15px;
    height:15px;
}

.cv .experience {
    left:-15px;
    margin-bottom:6px;
}

.cv .experience:last-child{
    margin-bottom:0;
}

.cv .details>p{
    font-size:9px;
    margin-top:2px;
    margin-bottom:2px;
}

.cv .date {
    font-size: 1em;
    line-height: 1;
    font-weight: 300;
    margin-top:2px;
    margin-bottom:2px;
}

// contact part styling

.contact{
    display: grid;
    grid-template-areas: "qr email" "qr college-email" "qr phone" "qr telegram" "fingerprint fingerprint";
}
// .contact>*{
//     border-radius: 10px;
//     background-color: var(--darken-rgba);
// }
.contact #email{
    grid-area:email;
}
.contact #phone{
    grid-area:phone;
}

.contact #email{
    grid-area:email;
}
.contact #telegram{
    grid-area:telegram;
}
.contact #college-email{
    grid-area:college-email;
}

.qr{
    grid-area: qr;
    padding: 2px;
    border-radius: 10px;
    background-color: var(--darken-rgba);
    display: flex;
align-items: center;
justify-content: center;
}
.qr>img{
    width:120px;
}

.contact .contactdetail{
    display:flex;
    align-items:center;
    border-radius: 5px;
    background-color: var(--darken-rgba);
}

#email,#phone,#college-email,#telegram{
    margin: 2px 0 2px 5px;
    padding: 0 4px 0 4px;
}

#fingerprint{
    margin: 5px 0 2px 0;
    padding: 2px 4px 2px 4px;
    grid-area: fingerprint;
} 
.contactdetail>img{
    width:15px;
    height:15px;
    margin:0 4px 0 0;
}

.cv #skills>p{
    margin:5px 0 5px 0;
}

.cv .contact a{
    white-space: pre-wrap;
    font-weight:400;
}

.cv #timeline a::before{
    content:""!important;
}