nav>div {
  display: flex;
  font-size: 1.3em;
  padding: 10px;
  font-weight: 300;
}

nav>div>a {
  float: right;
}

#logo {
  align-self: flex-start;
  border-radius:5px;
}

#navblog {
  margin-left: auto;
  margin-right: 14px;
  float: right;
}

#langpick>a {
margin:0 7px 0 7px;
}
nav{
  font-weight:300;
  padding:5px;
font-size:16px;
position:sticky;
top:0;
background-color: color-mix(in srgb, var(--secondary-color) 70%, transparent);
  backdrop-filter: blur(40px);
  z-index: 10;
}
nav a{
  font-weight:300;
}